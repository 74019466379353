'use client' // Error boundaries must be Client Components
 
import ButtonPrimary from '@/components/Button/ButtonPrimary'
import React from 'react'
import { useEffect } from 'react'
 
export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)
  }, [error])
 
  return (
    <div className="nc-Page404">
    <div className="lg:container xs:max-lg:mx-5 relative py-8 lg:py-20">
      {/* HEADER */}
      <header className="text-center max-w-2xl mx-auto space-y-3">
        <h2 className="text-xl md:text-3xl">🪔</h2>
        <h1 className="text-xl md:text-2xl font-semibold tracking-widest">
          Something went wrong!
        </h1>
<div>

        <ButtonPrimary aria-label="Reload this page"  onClick={()=> window.location.reload()} className="mt-1 ">
         Reload this page
        </ButtonPrimary>
</div>
<div>

        <ButtonPrimary aria-label=" Return Home Page" href="/" className="mt-1" >
          Return Home Page
        </ButtonPrimary>
</div>
      </header>
    </div>
  </div>
  )
}